<template>
  <b-modal :active.sync="value" id="selectPopupModal">
    <div v-if="showModal" class="modal-body" :style="modalStyle">
      <div class="content-modal opensans" ref="container">
        <div v-for="(item, index) in content" :key="index" class="parent-message-container">
          <!-- Carrusel si hay más de una imagen -->
          <b-carousel v-if="item.type === 'carousel'"
            ref="carousel"
            :pause-info="false"
            :arrow="false"
            :resize="false"
            :arrow-hover="false"
            :indicator="item.images.length > 1"
            :repeat="false"
            :autoplay="false"
            icon-pack="fa">
            <b-carousel-item v-for="(image, i) in item.images" :key="i">
              <img :src="image" alt="carousel-image">
            </b-carousel-item>
          </b-carousel>

          <!-- Imagen única si hay solo una imagen -->
          <img v-if="item.type === 'single'" :src="item.images[0]" alt="carousel-image">

          <!-- Texto -->
          <p class="opensans message-container" v-html="item.text" v-if="item.type === 'text'"></p>
          <div class="opensans message-container ql-align-default" v-html="item.text" v-if="item.type === 'list'"></div>
        </div>
      </div>
      <div class="btns-modal">
        <button class="opensans full-btn-modal btn-modal-fill button-modal" @click="acceptRumba">{{ $t('agree') }}</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ['value'],
  data () {
    return {
      popUpImages: [],
      message: null,
      popUpImagesDimensions: [],
      modalWidth: 350,
      showMessage: false,
      carousel: null,
      container: null,
      indicator: null,
      arrowLeft: null,
      arrowRight: null,
      messageHeight: 0,
      showModal: false,
      content: [],
      currentImages: []
    };
  },
  async mounted () {
    await this.getPopUpImages();
    setTimeout(() => {
      this.showModal = true;
      this.getHtmlElements();
    }, 300);
  },
  computed: {
    modalStyle () {
      return `max-width: ${this.modalWidth}px;`;
    }
  },
  methods: {
    getHtmlElements () {
      if (this.showMessage) {
        const thes = this;
        setTimeout(() => {
          thes.carousel = document.querySelector('.carousel');
          thes.container = thes.$refs.container;
          thes.indicator = document.querySelector('.carousel-indicator');
          thes.arrowLeft = document.querySelector('.carousel-arrow .has-icons-left');
          thes.arrowRight = document.querySelector('.carousel-arrow .has-icons-right');
          const message = thes.$refs.message;
          if (thes.message) {
            thes.messageHeight = message.clientHeight ? message.clientHeight : null;
          }
          if (thes.carousel) {
            thes.carousel.style.position = 'unset';
            thes.arrowLeft.style.top = 'unset';
            thes.arrowRight.style.top = 'unset';
            const carouselItems = document.querySelector('.carousel-items');
            const arrowElement = carouselItems.querySelector('.carousel-arrow');
            thes.carousel.appendChild(arrowElement);
          }
          this.validateImages();
        }, 500);
      }
    },
    validateImages () {
      if (this.popUpImages.length <= 0) {
        return;
      }
      this.adjustIndicators();
    },
    adjustIndicators () {
      const distanceFromBottom = this.container.scrollHeight - this.container.scrollTop - this.container.clientHeight - this.messageHeight;
      if (distanceFromBottom < 0) {
        requestAnimationFrame(() => {
          this.indicator.style.bottom = this.messageHeight - (distanceFromBottom + this.messageHeight) + 75 + 'px';
          this.arrowLeft.style.bottom = `calc(50% + ${this.messageHeight - (distanceFromBottom + this.messageHeight)}px)`;
          this.arrowRight.style.bottom = `calc(50% + ${this.messageHeight - (distanceFromBottom + this.messageHeight)}px)`;
        });
      } else {
        this.indicator.style.bottom = 65 + 'px';
        this.arrowLeft.style.bottom = '50%';
        this.arrowRight.style.bottom = '50%';
      }
    },
    async getPopUpImages () {
      let div = document.createElement('div');
      div.innerHTML = this.params.popupMsg;
      const elements = Array.from(div.childNodes);
      let group = [];
      elements.forEach(node => {
        if (node.nodeName === 'P' && node.querySelector('img')) {
          group.push(node.querySelector('img').src);
        } else if (node.nodeName === 'P' && node.innerHTML.trim() !== '') {
          if (group.length > 0) {
            // Si hay imágenes agrupadas
            if (group.length > 1) {
              this.content.push({
                type: 'carousel',
                images: group,
                index: this.content.length // Indice para gestionar el carrusel
              });
            } else {
              this.content.push({
                type: 'single',
                images: group
              });
            }
            group = []; // Reiniciar grupo
          }
          // Agregar texto
          this.content.push({
            type: 'text',
            text: node.innerHTML.trim()
          });
        } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
          this.content.push({
            type: 'list',
            text: node.innerHTML
          });
        }
      });
      // Manejar grupo restante
      if (group.length > 0) {
        if (group.length > 1) {
          this.content.push({
            type: 'carousel',
            images: group,
            index: this.content.length
          });
        } else {
          this.content.push({
            type: 'single',
            images: group
          });
        }
      }
      this.currentImages = Array(this.content.length).fill(0);
      this.message = div.innerHTML;
      const textAlone = div.innerText || div.textContent;
      const cleanText = textAlone.replace(/\uFEFF/g, '').trim();
      if (cleanText !== '') {
        this.showMessage = true;
      }
    },
    setModalWidth () {
      const dimensions = this.popUpImagesDimensions[0];
      if (dimensions && dimensions.width > 0 && dimensions.height > 0) {
        if (dimensions.width === dimensions.height) {
          this.modalWidth = 520;
        } else if (dimensions.width > dimensions.height) {
          this.modalWidth = 350;
        } else {
          this.modalWidth = 310;
        }
      }
    },
    acceptRumba () {
      this.$emit('input', false);
    },
    getBackgroundColor (color, type = null, color2 = null) {
      if (type === 'back') {
        return {
          background: color
        };
      }
      if (type === 'p') {
        return {
          color: color
        };
      }
      if (type === 'btn') {
        return {
          'background-color': color,
          color: color2
        };
      }
      return {
        'background-color': color
      };
    },
    setTextWidth () {
      const messageLength = this.message ? this.message.length : 0;
      if (messageLength <= 0) return;
      if (messageLength <= 300) this.modalWidth = 400;
      else if (messageLength <= 600) this.modalWidth = 500;
      else if (messageLength <= 900) this.modalWidth = 600;
      else this.modalWidth = 350;
    }
  }
};
</script>
<style lang="scss">
/* Carousel customization */
.carousel .carousel-indicator .indicator-item .indicator-style {
  background: transparent;
}
.carousel .carousel-indicator .indicator-item.is-active .indicator-style, .carousel .carousel-indicator .indicator-item .indicator-style:hover {
  background: var(--colorPopup);
  border: 1px solid var(--colorPopup);
}
.carousel-arrow .icon:hover {
  border: 1px solid var(--colorPopup);
}
.carousel-arrow .icon {
  background: transparent;
  color: var(--colorPopup);
  border: none;
  &:hover{
    border: none;
  }
  i{
    font-size: 25px;
  }
}
.carousel .carousel-indicator .indicator-item .indicator-style {
  border: 1px solid var(--colorPopup);
}

.carousel .carousel-indicator.is-inside.is-bottom {
  margin-bottom: 10px;
}

/* Modal Customization */
.opensans {
  font-family: 'Source Sans Pro'!important;
}
#selectPopupModal {
  .modal-content {
    height: 100% !important;
    max-height: 100% !important;
    min-width: 350px !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-body {
    background: var(--backgroundColorPopup);
    color: var(--colorPopup);
    min-height: 184px;
    max-height: calc(100% - 10px);
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 10px 5px 10px 10px;
    min-width: 350px;
    width: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.60);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .content-modal {
    max-height: calc(100% - 55px);
    height: auto;
    overflow: auto;
    margin: 0 auto;
    min-width: 288px;
    width: auto;
    border-radius: 10px;
    padding-right: 5px;
    .carousel {
      position: relative!important;
      &:focus-visible {
        outline: none!important;
      }
      &-items:focus-visible {
        outline: none!important;
      }
      &-item:focus-visible {
        outline: none!important;
      }
      .carousel-arrow {
        display: none!important;
      }
      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        &:focus-visible {
          outline: none!important;
        }
      }
    }
    img {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      &:focus-visible {
        outline: none!important;
      }
    }
    .parent-message-container {
      text-align: center;
      border-radius: 10px;
    }
    .message-container {
      text-align: center;
      color: var(--colorPopup) !important;
      font-size: 14px;
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span, strong, em, u, s{
        color: var(--colorPopup) !important;
      }
    }
  }
  .btns-modal {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    .full-btn-modal {
      align-items: center;
      background-color: var(--backgroundCirclePopup);
      border-radius: 6px;
      border: none;
      color: var(--colorCirclePopup);
      display: flex;
      font-size: 16px;
      height: 40px;
      justify-content: center;
      max-width: 340px;
      padding: 5px 20px;
      width: 100%;
    }
  }
  ::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000 !important;
    border-radius: 10px;
  }
  .ql-align-default li {
    text-align: left;
  }
  .ql-align-center {
    text-align: center!important;
  }
  .ql-align-right {
    text-align: right!important;
  }
  @media screen and (max-width: 767px){
    .content-modal{
      .message-container{
        padding: 0 12px;
      }
    }
  }
}
</style>
