<template>
  <main class="bgimg-1 main-select" :class="{noImgSelect: !showNav}" v-if="vendor && design && params">
    <NavBar :isButton="true" back="home" @actionModalEvents="actionModalEvents"></NavBar>
    <Stepper :paso="1"></Stepper>
    <TypeReserva class="container" v-if="showTypeList" v-model="showTypeList"></TypeReserva>
    <AlertRumba v-if="selectedTypeReservation === 'Rumba' && showAlert" v-model="showAlert"></AlertRumba>
    <MessageVendor v-if="programmedModal" v-model="showAlert"></MessageVendor>
    <PopUpEventDescription></PopUpEventDescription>
    <Events @actionModalEvents="actionModalEvents" :showEventStatus="showEvents" v-show="!embedSystem"/>
    <div class="aux-div-for-bg-events">
      <SideTabsSelect @actionModalEvents="actionModalEvents" :showEvents="showEvents" v-show="!embedSystem"/>
      <ProcessReserva class="container" v-if="!showTypeList"></ProcessReserva>
    </div>
    <Footer v-model="showTypeList"></Footer>
  </main>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
import NavBar from '@/components/Select/navBar';
import Stepper from '@/components/Select/stepper';
import TypeReserva from '@/components/Select/typeReserva';
import SideTabsSelect from '@/components/Select/SideTabsSelect';
import MessageVendor from '@/components/Select/popupMsg';
import PopUpEventDescription from '@/components/Select/PopUpEventDescription';
import ProcessReserva from '@/components/Select/processReserva';
import Footer from '@/components/Select/footer';
import Events from '@/components/EventsV2';

export default {
  name: 'Select',
  components: {
    NavBar,
    Stepper,
    TypeReserva,
    MessageVendor,
    ProcessReserva,
    Footer,
    PopUpEventDescription,
    Events,
    SideTabsSelect
  },
  data () {
    return {
      showTypeList: false,
      showAlert: true,
      IsModalShowend: true,
      showEvents: false,
      giftCardSection: false,
      programmedModal: false,
      showNav: true
    };
  },
  computed: {
    ...mapGetters({
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, false);
    this.$store.commit(whiteLabelTypes.mutations.setModalExperience, false);
    let vendorId = { vendorId: this.$store.getters['whiteLabel:vendorId'] };
    this.loadScriptPersonalize(this.vendor.id);
    if (this.params.listReservationType) {
      this.showTypeList = true;
      this.$store.dispatch({
        type: 'whiteLabel:getReservationTypes',
        data: vendorId
      });
    } else {
      this.showTypeList = false;
    }
    this.$store.dispatch({
      type: 'whiteLabel:branch',
      data: { url: this.$store.state.vendorUrl }
    });
    this.$store
      .dispatch({ type: 'reservation:times', data: vendorId })
      .then(() => {
        this.$store.dispatch({ type: 'reservation:schedules', data: vendorId });
        this.$store.dispatch({ type: 'reservation:blocked', data: { vendorId: this.vendor.id } });
        this.$store.dispatch({ type: 'event:allEvents', data: vendorId });
      });
    if (this.params.isActiveAvailabilityRange === 1) {
      this.$store.dispatch({ type: 'reservation:availabilityCalendar', data: { vendorId: this.vendor.id } });
    }
    this.$store.commit(whiteLabelTypes.mutations.setSection, 1);
    this.initReservation();
    const urlParams = new URLSearchParams(window.location.search);
    const tokenAuthSelect = urlParams.get('oAuthToken');
    if (window.localStorage.getItem('deleteFuture')) {
      let reservation = JSON.parse(atob(window.localStorage.getItem('_reservation')));
      if (reservation && reservation.futureReservationsId) {
        this.$store.dispatch({
          type: 'reservation:deletePreReservation',
          data: reservation
        });
        window.localStorage.removeItem('deleteFuture');
      }
    }
    if (!tokenAuthSelect) {
      window.localStorage.removeItem('_reservation');
      window.localStorage.removeItem('_reservation_link');
      window.localStorage.removeItem('_reservationOk');
      window.localStorage.removeItem('_reservation_time');
      window.localStorage.removeItem('_reservation_more_time');
      window.localStorage.removeItem('payReservations');
      window.localStorage.removeItem('payReservationBoys');
      window.localStorage.removeItem('payReservationsFixed');
      window.localStorage.removeItem('applyStrong');
      window.localStorage.removeItem('pricing');
      window.localStorage.removeItem('_decoration_cost');
      window.localStorage.removeItem('zoneId');
      window.localStorage.removeItem('_oldPayExperience');
      window.localStorage.removeItem('_oldPayExperienceFull');
      window.localStorage.removeItem('_invalid_alert_experience');
    }
    this.$store.commit(reservationTypes.mutations.setSelectedDateTemporal, null);
    this.$on('showTypeListReservation', e => {
      this.showTypeList = e;
      this.$store.commit(
        reservationTypes.mutations.setSelectedTypeReservation,
        'Normal'
      );
    });
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);

    // popup programado
    const validationShowModal = this.getValidationPopUpMsg();
    if (validationShowModal && this.params.popupMsg !== null) {
      this.programmedModal = true;
    }
    const routeParams = this.$route.query;
    if (Object.keys(routeParams).length > 0) {
      if (routeParams.embed) {
        if (routeParams.embed) {
          this.showNav = false;
        }
      }
    }
  },
  methods: {
    getValidationPopUpMsg () {
      const currentDate = this.$moment().format('YYYY-MM-DD');
      const currentTime = this.$moment().format('HH:mm');

      // Valores de rango
      const startDate = this.params.startDateSelect;
      const endDate = this.params.endDateSelect; // Puede ser null
      const startHour = this.params.startHourSelect;
      const endHour = this.params.endHourSelect; // Puede ser null

      const alertOnboardingToShow = () => {
        let isStartDateHourMatch = true;
        let isEndDateHourMatch = true;

        // Validar inicio: fecha y hora inicial deben coincidir
        if (startDate !== null && startDate !== '') {
          if (currentDate === startDate) {
            // Si es el día de la fecha inicial, validar la hora inicial
            isStartDateHourMatch = startHour === null || currentTime >= startHour;
          } else {
            // Validar solo la fecha inicial (sin considerar la hora)
            isStartDateHourMatch = currentDate >= startDate;
          }
        }

        // Validar final: fecha y hora final deben coincidir
        if (endDate !== null && endDate !== '') {
          if (currentDate === endDate) {
            // Si es el día de la fecha final, validar la hora final
            isEndDateHourMatch = endHour === null || currentTime < endHour;
          } else {
            // Validar solo la fecha final (sin considerar la hora)
            isEndDateHourMatch = currentDate <= endDate;
          }
        }
        // Ambas condiciones deben cumplirse
        return isStartDateHourMatch && isEndDateHourMatch;
      };
      return alertOnboardingToShow();
    },
    actionModalEvents (status) {
      this.showEvents = status;
    }
  }
};
</script>
<style lang="scss">
html{
  overflow-y: auto;
  overflow-x: hidden!important;
}
main {
  display: flex;
  align-items: unset;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  min-height: 100vh;
}
.bookmark {
  fill: #fff; /* sustituir */
  fill-rule: evenodd;
}
.main-select {
  background-position: top;
  background-image: var(--imgSelect);
  z-index: 100;
  backface-visibility: hidden;
}
.btn-siguiente {
  height: 44px;
}
.card-400 {
  border-radius: 10px;
}
.logoDiv {
  margin-top: -10px;
}
.m-vertical-50 {
  padding: 20px;
}
.card {
  border-radius: 0.7rem !important;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-400 {
  height: 100% !important;
}
.iconPeople {
  margin-left: -40px;
}
#siguienteButton,
.previus-btn {
  display: none;
}
#people {
  font-size: 120px;
  line-height: 120px;
  // margin-left: 18px;
}
.bgimg-1 {
  background-size: cover;
}
.Reservation {
  #gaira {
    width: 100%;
    .lite-padding {
      padding: 5px;
    }
    #people {
      font-size: 30px;
      line-height: 30px;
      font-weight: bold;
    }
    .Reservation-buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .number_button {
      font-size: 30px;
      line-height: 30px;
      font-weight: bold;
    }
    .Reservation-subtitle {
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: var(--cardSubTitleColor);
    }
  }
  .gaira-sub-text {
    text-align: center;
    margin-top: 15px;
  }
}
.event-v2-container{
  &__tab{
    display: flex;
    right: -187px;
    box-shadow: -10px 8px 7px rgba(0, 0, 0, 0.1607843137);
    background: var(--cardBackgroundColorAditional);
    width: 33px;
    height: 133px;
    border-radius: 13px 0px 0px 13px;
    transition: all 0.5s;
    margin-right: -1px;
    &__div {
      cursor: pointer;
      border-radius: 13px 0px 0px 13px;
      flex: 1;
      border-right: solid 1px var(--cardTitleColor);
      display: flex;
      justify-content: center;
      align-items: center;
      > div{
          color: var(--cardTitleColorAditional);
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: -4px;
      }
    }
    &__button-cont{
      flex: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
    }
  }
}

@media (max-width: 959px) {
  #selectDate,
  #selectHour,
  #reservationButton {
    display: none;
  }
  .card-400 {
    height: 100%;
  }
  #siguienteButton {
    position: fixed;
    bottom: 5px;
    margin-left: 5px;
  }
  #people {
    font-size: 116px;
    line-height: 87px;
    margin-bottom: 20px;
    // margin-left: 18px;
  }
  .footers {
    padding-bottom: 14px;
  }
  .center-responsive {
    text-align: center !important;
  }
  .p-top-50 {
    padding-top: 5% !important;
  }
  .blockPicker {
    margin-bottom: -30px;
  }
  .previus-btn {
    position: absolute;
    margin-left: -15px;
    top: 50%;
  }
}
.aux-div-for-bg-events{
  position: relative;
  display: inherit;
  align-content: center;
  flex-grow: 1;
  align-items: center;
  place-content: center;
}
.footer{
  z-index: 7;
}
@media (min-height: 768px) {
  .footers {
    left: 0;
    width: 100%;
    color: white;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .footers {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}
.noImgSelect {
  background-image: none;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
}
</style>
