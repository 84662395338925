<template>
  <div class="block-hour-event">
        <div class="box-hour columns is-mobile" v-if="dataReservationEdit.vendorId !== 72">
      <span

        :disabled="this.now >= new Date(this.selectedDate)"
        @click="previousDate"
        class="btn-arrow column pointer"
      >
        <i class="fa fa-angle-left" ></i>
      </span>
      <ul class="dates-list column no-laterals-padding is-four-fifths">
        <li
          class="data-big"
        >{{ new Date(selectedDate) | moment('timezone', vendorTimezone, 'dddd, DD MMM') }}</li>
      </ul>
      <span
        :disabled="blockedNext"
        @click="nextDate"
        class="btn-arrow column has-text-right pointer"
      >
        <i class="fa fa-angle-right" ></i>
      </span>
    </div>
    <b-button
      :disabled="preDisabled"
      @click="previus()"
      class="swi-prev btn-arrows"
      href="javascript:void(0)"
      v-if="Array.isArray(time) && time.length > 0"
    >
      <i aria-hidden="true" class="fa fa-angle-up" style="font-size:25px"></i>
    </b-button>
    <div>
      <div id="customSwiper">
        <div @scroll="probeScroll" :key="index" class="contSwiper" :class="slide.length <= 3 ? 'cont-swiper-aux-grid': 'cont-swiper-aux-grid'" v-for="(slide, index) in sortedAndChunkedTime">
          <div
            :class="{'active': data.status && parseInt(data.inicio) === parseInt(timeSelected)  , 'disabled': !data.status , 'btnSwiper--doubleHour':data.fin}"
            :key="i"
            @click="setTimeHour(data)"
            class="btnSwiper btnSwiper--EventType"
            v-for="(data, i) in slide"
          >
            <i class="fa fa-exclamation-circle" v-if="event.isCover"></i>
            <span
              class="text"
            >{{ parseInt(data.inicio) | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
            <span v-if="data.fin" class="text separatorSwipper">-</span>
            <span
              v-if="data.fin"
              class="text"
            > {{ parseInt(data.fin) | moment('timezone', vendorTimezone, 'hh:mm a')}}</span>
          </div>
        </div>
      </div>
    </div>
    <b-button
      :disabled="preDisabledNext"
      @click="next()"
      class="swi-next btn-arrows"
      href="javascript:void(0)"
      v-if="Array.isArray(time) && time.length > 0"
    >
      <i aria-hidden="true" class="fa fa-angle-down" style="font-size:25px"></i>
    </b-button>
    <div class="legendHour">
      <div class="legendHour-availability"></div>
      <p class="text">{{ $t('availableHour') }}</p>
      <div class="legendHour-not-availability"></div>
      <p class="text">{{ $t('noAvailableHour') }}</p>
    </div>
    <span v-if="event.isCover">
      <span class="f12 fw-600 white--text" style="padding: 10px 10px;">
        <i class="fa fa-exclamation-circle white--text"></i>
        &nbsp;{{ $t('contributionArtist') }}.
      </span>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import eventTypes from '@/store/types/event';
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  props: ['time', 'event'],
  data () {
    return {
      now: null,
      timeSelected: null,
      stop: false,
      preDisabled: false,
      preDisabledNext: false,
      blockedNext: false
    };
  },
  computed: {
    ...mapGetters({
      popupAlertEvent: eventTypes.getters.popupAlertEvent
    }),
    sortedAndChunkedTime () {
      const sortedTime = [...this.time].sort((a, b) => a.inicio - b.inicio);
      return this.chunkArray(sortedTime, 12);
    }
  },
  mounted () {
    this.now = new Date();
    this.probeScroll();
  },
  watch: {
    selectedHour () {
      this.timeSelected = this.selectedHour;
    }
  },
  methods: {
    limitAvailabilityCalendar (date) {
      let limitDay = this.params.dayCalendarAvailability;
      let datesAvailables = [];
      if (limitDay > 0) {
        for (var i = 0; i < limitDay; i++) {
          datesAvailables.push(
            parseInt(
              this.$moment()
                .add(i, 'days')
                .seconds(0)
                .minutes(0)
                .hours(0)
                .milliseconds(0)
                .format('x')
            )
          );
        }
        if (_.indexOf(datesAvailables, date) >= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    nextDate () {
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      let date = this.$moment(new Date(this.selectedDate)).add(1, 'days');
      let blockedCalendarAvailability = this.limitAvailabilityCalendar(
        parseInt(date.format('x'))
      );
      if (!blockedCalendarAvailability) {
        let time = new Date(date);
        time = time.getTime();
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
        this.$store.commit(reservationTypes.mutations.setSelectedDate, time);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
        this.$store
          .dispatch({
            type: 'whiteLabel:avalibilityHour',
            data: {
              timestamp: time,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              people: this.selectedPeople,
              zone: this.selectedZone,
              typeReservation: this.selectedTypeReservation,
              tableId: this.selectedTable
            }
          })
          .then(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          });
      } else {
        this.blockedNext = true;
      }
    },
    previousDate () {
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      this.blockedNext = false;
      let date = this.$moment(new Date(this.selectedDate)).subtract(1, 'days');
      let blockedCalendarAvailability = this.limitAvailabilityCalendar(
        parseInt(date.format('x'))
      );
      if (!blockedCalendarAvailability) {
        date = this.$moment(date).format('YYYY/MM/DD');
        let time = new Date(date);
        if (this.now <= new Date(this.selectedDate)) {
          time = time.getTime();
          this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
          this.$store.commit(reservationTypes.mutations.setSelectedDate, time);
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
          this.$store
            .dispatch({
              type: 'whiteLabel:avalibilityHour',
              data: {
                timestamp: time,
                vendorId: this.$store.getters['whiteLabel:vendorId'],
                people: this.selectedPeople,
                zone: this.selectedZone,
                typeReservation: this.selectedTypeReservation,
                tableId: this.selectedTable
              }
            })
            .then(() => {
              this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
            });
        }
      }
    },
    chunkArray (myArray, chunkSize) {
      var index = 0;
      var arrayLength = myArray.length;
      var tempArray = [];
      for (index = 0; index < arrayLength; index += chunkSize) {
        let myChunk = myArray.slice(index, index + chunkSize);
        tempArray.push(myChunk);
      }
      this.totalSlide = tempArray.length;
      return tempArray;
    },
    setTimeHour (info) {
      if (info.status === 1) {
        this.timeSelected = parseInt(info.inicio);
        let evento = null;
        let maxPeople = 0;
        this.event.forEach((mapEvent) => {
          mapEvent.times.forEach((timeEvent) => {
            if (timeEvent.id === info.eventDateId) {
              evento = mapEvent;
            }
          });
          if (mapEvent.id === info.eventId) {
            maxPeople = mapEvent.maxPeople;
          }
        });
        if (evento) {
          evento.eventDateId = info.eventDateId;
        } else {
          console.error('Borre cache porque no tengo times');
        }
        this.$store.commit(
          reservationTypes.mutations.setSelectedHour,
          parseInt(info.inicio)
        );
        this.$store.commit(
          eventTypes.mutations.setReservationEvent,
          evento
        );
        this.$emit('setPeopleEvent', maxPeople);
      } else {
        this.$store.commit(eventTypes.mutations.setPopupAlertEvent, true);
        let thes = this;
        setTimeout(() => {
          thes.$store.commit(eventTypes.mutations.setPopupAlertEvent, false);
        }, 5000);
      }
    },
    checkTime (slide) {
      let aux = 0;
      let thes = this;
      _.forEach(slide, function (item) {
        if ((item.date === thes.times && item.status) || (parseInt(item.date) > thes.theHour && item.status && thes.times == null)) {
          aux = 1;
        }
      });
      if (aux === 1) {
        return true;
      } else {
        return false;
      }
    },
    previus () {
      const element = document.getElementById('customSwiper');
      this.scrollToSmoothly(element, element.scrollTop - 80);
    },
    next () {
      const element = document.getElementById('customSwiper');
      this.scrollToSmoothly(element, element.scrollTop + 80);
    },
    probeScroll () {
      const element = document.getElementById('customSwiper');
      if (element.scrollHeight <= element.offsetHeight) {
        this.preDisabled = true;
        this.preDisabledNext = true;
      } else {
        if (element.scrollTop > 0) {
          this.preDisabled = false;
        } else {
          this.preDisabled = true;
        }
        if (element.scrollHeight <= element.scrollTop) {
          this.preDisabledNext = true;
        } else {
          this.preDisabledNext = false;
        }
      }
    },
    scrollToSmoothly (element, targetPosition) {
      const startPosition = element.scrollTop;
      const distance = targetPosition - startPosition;
      const duration = 300; // Duración de la animación en milisegundos
      const startTime = performance.now();
      function animation (currentTime) {
        const elapsedTime = currentTime - startTime;
        const t = Math.min(1, elapsedTime / duration);
        const newPosition = startPosition + distance * t;
        element.scrollTop = newPosition;
        if (elapsedTime < duration) {
          requestAnimationFrame(animation);
        }
        this.probeScroll();
      }

      requestAnimationFrame(animation);
    }
  }
};
</script>
<style scoped lang="scss">
.cont-swiper-aux-grid{
  display: flex;
  justify-items: center;
  align-content: flex-start;
  padding: 5px 0;
}
.block-hour-event {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.disabled,
.disabled:hover {
  background-color: rgba(181, 179, 179, 0.34) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  opacity: unset !important;
}
.timeSelectedEvent {
  color: var(--color2) !important;
  background-color: var(--color1) !important;
}
.NottimeSelectedEvent {
  color: var(--color1);
  border: 1px solid var(--color1);
}
.eventhouru {
  width: 145px !important;
  height: 40px !important;
  span {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
