<template>
  <b-modal :active.sync="bandera" aria-close-label="Close message" id="modal-avalibility-crusale">
    <div id="ventaCruzada">
      <i @click="volver()" class="icon-mks icon_alert_cl ajust"></i>
      <div class="cont-test-cat" id="cont-cont-cat">
        <span class="text-header">{{ $t('findAvailableOther') }}</span>
        <i
          @click="scrolling('left')"
          class="icon-mks icon_arrow_left_vc"
          id="arrow-left"
          v-if="initCard > 0"
        ></i>
        <i
          @click="scrolling('right')"
          class="icon-mks icon_arrow_right_vc"
          id="arrow-right"
          v-if="showIfLimitAction"
        ></i>
        <transition name="fade">
          <div class="test-cat" id="contenedor-categoria">
            <div
              :class="{'no-space': !item.vendorId}"
              :index="i"
              :key="i"
              class="restaurant"
              v-for="(item, i) in items"
            >
              <div class="restaurant-hour" v-if="item.vendorId">
                <div class="img-cont">
                  <img
                    :alt="item.displayName"
                    :src="item.imageColor"
                    :title="item.displayName"
                    class="image-rest"
                  />
                </div>
                <span v-if="item.sede" class="sede-title">{{  item.sede }}</span>
                <div class="btn-hour-vc">
                  <div
                    :class="{ 'btn-active': hourSelected == item.vendorId + '_' + key }"
                    :key="key"
                    @click="nextVendor(item.url + 'select?selectedPeople=' + selectedPeople + '&selectedDate=' + selectedDate + '&selectedHour='+ key + '&source=cross-selling', item.vendorId , key)"
                    class="btn-vc"
                    v-for="(value, key) in item.availability"
                  >
                    <span
                      class="text-btn-vc"
                    >{{ parseInt(key) | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <div class="cont-btn-res">
          <a
            :class="{ 'disabled': !urlVendor, 'btn-next-active': urlVendor}"
            :href="urlVendor"
            class="btn-next"
          >{{ $t('goToRestaurant') }}</a>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import _ from 'lodash';

export default {
  data () {
    return {
      hourSelected: null,
      bandera: false,
      initCard: 0,
      endCard: 4,
      urlVendor: null,
      showIfLimitAction: false,
      items: []
    };
  },
  mounted () {
    window.addEventListener('resize', this.isResponsive);
  },
  methods: {
    ...mapActions({
      getAvalibilityGroup: whiteLabelTypes.actions.getAvalibilityGroup
    }),
    groupAvalibility () {
      this.bandera = true;
    },
    getAvailabilityMessage () {
      if (this.params.activatePhoneAvailability === 1) {
        const phone = `<span><strong>${this.vendor.phone}</strong></span>`;
        const message = this.params.messageAvailability.replace('TELEFONO', phone);
        return message;
      }
      return this.params.messageAvailability;
    },
    volver () {
      this.bandera = false;
      this.urlVendor = null;
    },
    nextVendor (url, vendor, key) {
      if (url === this.urlVendor) {
        this.urlVendor = null;
        this.hourSelected = null;
      } else {
        this.urlVendor = url;
        this.hourSelected = vendor + '_' + key;
      }
    },
    scrolling (dir) {
      if (dir === 'left') {
        this.initCard = this.initCard - 1;
        this.endCard = this.endCard - 1;
        this.limitCards();
      } else {
        this.initCard = this.initCard + 1;
        this.endCard = this.endCard + 1;
        this.limitCards();
      }
    },
    isResponsive () {
      if (parseInt(window.innerWidth) >= 861 && parseInt(window.innerWidth) <= 1050) {
        this.initCard = 0;
        this.endCard = 3;
      } else if (parseInt(window.innerWidth) >= 630 && parseInt(window.innerWidth) <= 860) {
        this.initCard = 0;
        this.endCard = 2;
      } else if (parseInt(window.innerWidth) <= 629) {
        this.initCard = 0;
        this.endCard = 1;
      } else {
        this.initCard = 0;
        this.endCard = 4;
      }
      this.limitCards();
    },
    showIfLimit () {
      const max = this.avalabilityGroup.length || 0;
      if (parseInt(window.innerWidth) >= 861 && parseInt(window.innerWidth) <= 1050 && this.avalabilityGroup.length > 3) {
        if (this.endCard >= max) {
          this.showIfLimitAction = false;
        } else {
          this.showIfLimitAction = true;
        }
      } else if (parseInt(window.innerWidth) >= 630 && parseInt(window.innerWidth) <= 860 && this.avalabilityGroup.length > 2) {
        if (this.endCard >= max) {
          this.showIfLimitAction = false;
        } else {
          this.showIfLimitAction = true;
        }
      } else if (parseInt(window.innerWidth) <= 629 && this.avalabilityGroup.length > 1) {
        if (this.endCard >= max) {
          this.showIfLimitAction = false;
        } else {
          this.showIfLimitAction = true;
        }
      } else if (this.avalabilityGroup.length > 4) {
        if (this.endCard >= max) {
          this.showIfLimitAction = false;
        } else {
          this.showIfLimitAction = true;
        }
      } else {
        this.showIfLimitAction = false;
      }
    },
    limitCards () {
      this.showIfLimit();
      if (this.avalabilityGroup.length > 0) {
        this.items = this.avalabilityGroup.slice(this.initCard, this.endCard);
      } else {
        this.items = this.avalabilityGroup;
      }
    },
    openUrl (url) {
      if (url) {
        window.location.href = url;
      }
    },
    restaurantGroup () {
      let group1 = [
        { id: 172, name: 'Seratta' },
        { id: 173, name: 'Viva la vida' }
      ];
      let group2 = [35, 29, 42, 47, 48, 95, 43, 194, 131, 127, 128, 133, 126, 39, 154];
      if (_.find(group1, { id: parseInt(this.params.vendorId) })) {
        if (this.params.vendorId === 172) {
          return 'Viva la vida';
        } else if (this.params.vendorId === 173) {
          return 'Seratta';
        }
      } else if (_.indexOf(group2, parseInt(this.params.vendorId)) !== -1) {
        return 'Grupo DLK';
      } else {
        return 'restaurantes asociados';
      }
    },
    restaurantGroupFind () {
      let group1 = [
        { id: 172, name: 'Seratta' },
        { id: 173, name: 'Viva la vida' }
      ];
      let group2 = [35, 29, 42, 47, 48, 95, 43, 194, 131, 127, 128, 133, 126, 39, 154];
      if (_.find(group1, { id: parseInt(this.params.vendorId) })) {
        if (this.params.vendorId === 172) {
          return 'en Viva la vida';
        } else if (this.params.vendorId === 173) {
          return 'en Seratta';
        }
      } else if (_.indexOf(group2, parseInt(this.params.vendorId)) !== -1) {
        return 'en Grupo DLK';
      } else {
        return 'otros restaurantes';
      }
    }
  },
  watch: {
    avalabilityGroup () {
      if (this.avalabilityGroup.length > 0) {
        this.bandera = true;
        this.isResponsive();
      } else {
        this.bandera = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.sede-title {
  color: #897454;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 18px;
}
@media screen and (min-width: 861px) and (max-width: 1050px) {
  #ventaCruzada {
    width: 800px !important;
  }
}
@media screen and (min-width: 630px) and (max-width: 860px) {
  #ventaCruzada {
    width: 600px !important;
  }
  .text-header {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 629px) {
  #ventaCruzada {
    width: 290px !important;
  }
  .text-header {
    font-size: 16px !important;
    line-height: unset !important;
    width: 210px !important;
    padding: unset !important;
    margin-bottom: 10px;
  }
  .ajust {
    right: 15px !important;
  }
  .restaurant {
    margin-right: unset !important;
  }
}
#ventaCruzada {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 420px;
  width: 1039px;
  margin: 0 auto;
  .restaurant-hour {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    .img-cont {
      height: 100px;
      max-height: 100px;
      display: flex;
      align-items: center;
      margin-top: 15px;
    }
    .btn-hour-vc {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 15px;
      .btn-vc {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 30px;
        color: #897454;
        border: 1px solid #897454;
        margin: 5px 10px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .image-rest {
    max-width: 100px;
    max-height: 100px;
  }
}
.ajust {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 40;
}
.text-header {
  color: #897454;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  cursor: default;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.text-btn-vc {
  font-size: 12px;
  font-weight: 500;
}
.cont-test-cat {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  #arrow-left {
    color: #000;
    position: absolute;
    cursor: pointer;
    left: 5px;
    top: 54%;
    transform: translateY(-50%);
    padding: 6px;
    font-size: 40px;
    z-index: 1;
  }
  #arrow-right {
    color: #000;
    position: absolute;
    cursor: pointer;
    right: 5px;
    top: 54%;
    transform: translateY(-50%);
    padding: 6px;
    font-size: 40px;
    z-index: 1;
  }
  .test-cat {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;
    padding: 15px;
    animation: fadeSlow 1s;
    width: 100%;
    .restaurant {
      width: 230px;
      height: 260px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      &.no-space {
        width: 0;
        height: 0;
      }
    }
    &.no-margin {
      margin: 10px 0;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
  }
}
.btn-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  font-weight: bold;
  display: flex;
  height: 30px;
  align-items: center;
  border-radius: 6px;
  background: #444b57;
  color: #ffffff;
  opacity: 0.4;
  &-active {
    background-color: #897454;
    opacity: unset;
  }
}
.disabled {
  background-color: #444b57 !important;
  color: white !important;
  border: none !important;
  opacity: 0.5 !important;
}
.btn-active {
  color: #ffffff !important;
  background-color: #897454;
  font-weight: bold;
  border: 1px solid #897454;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.cont-btn-res {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px 0px 0px;
  width: 100%;
}
.icon_arrow_right_vc {
  margin-right: unset !important;
  margin-left: 5px;
}
</style>
