<template>
  <div class="Reservation" v-if="design && vendor && params">
    <div
      :class="{'mtop60':(design.typeLogo == 'border')}"
      class="columns Reservation-content is-mobile"
    >
      <div class="column is-vcentered Reservation-card is-mobile" id="selectPeople">
        <div class="Reservation-card typeReservation">
          <h1 class="Reservation-title">{{ $t('select') }}</h1>
          <p class="Reservation-subtitle">{{ $t('typeReservation') }}</p>
          <div class="Reservation-divider"></div>
          <div class="buton-card">
            <button
              :key="k"
              @click="selectType(lrt)"
              class="btn-hour"
              v-for="(lrt, k) in listReservationTypes"
            >{{ lrt.label }}</button>
          </div>
        </div>
      </div>
    </div>
    <CustomStyles>
      .btn-hour:hover {
      color: {{ design.color2 }} !important;
      border: 1px solid {{ design.color2 }} !important;
      background-color: {{ design.color1 }} !important;
      }
    </CustomStyles>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import CustomStyles from '@/components/customStyles';

export default {
  props: ['value'],
  components: { CustomStyles },
  methods: {
    selectType (lrt) {
      this.$store.commit(
        reservationTypes.mutations.setSelectedTypeReservation,
        lrt.type
      );
      if (lrt.seatingSectionId !== undefined && lrt.seatingSectionId != null) {
        this.$store.commit(
          reservationTypes.mutations.setSelectedZone,
          lrt.seatingSectionId
        );
      }
      this.$emit('input', false);
    }
  }
};
</script>
<style lang="scss" scope>
.buton-card {
  color: var(--cardTitleColor);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .btn-hour {
    color: var(--cardTitleColor);
    margin-top: 10px;
    width: 100%;
    border: 1px solid var(--cardTitleColor);
  }
}
</style>
